html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

input,
textarea,
button,
select,
a,
.fab {
  -webkit-tap-highlight-color: transparent;
}
