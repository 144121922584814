$backgorund-color: #fff;
$color: #5f6368;
$border-color: #d6d6d6a9;

.editor {
  display: flex;
  height: 100%;

  .header {
    background-color: $backgorund-color;
    border-bottom: 1px solid $border-color;
    color: $color;

    .toggle-menu {
      display: none;
    }
  }

  .main {
    flex: 1;
    position: relative;

    .sider {
      display: flex;
      flex-direction: column;
      padding: 0.7rem;
      background-color: $backgorund-color;
      border-right: 1px solid $border-color;

      .ant-layout-sider-children > * {
        margin-bottom: 0.9rem;
      }

      .background-color {
        display: flex;
        align-items: center;
        span {
          margin-right: 0.3rem;
        }
      }
    }

    .content {
      background-color: $backgorund-color;

      .model-viewer {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }

  .upload {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;

    input[type='file'] {
      display: none;
    }

    .icon {
      font-size: 4.1rem;
      color: $color;
    }

    .title {
      margin-top: 1.1rem;
      font-size: 1.1rem;
    }

    .subtitle {
      font-size: 1.1rem;
    }

    .button {
      margin-top: 0.7rem;
    }
  }

  .splash-screen {
    & > .items {
      border: 1px solid $border-color;
      border-radius: 7px;
      display: flex;
      flex-direction: column;
      padding: 0.3rem;
      margin-top: 0.2rem;
      & > .item {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        &:not(:last-child) {
          margin-bottom: 0.7rem;
        }
      }
    }
  }
}

@media screen and (max-width: 570px) {
  .editor {
    .header {
      .title {
        display: none;
      }
      .toggle-menu {
        display: unset;
        margin-right: 0.2rem;
      }
      padding-left: 10px;
      padding-right: 10px;
    }
    .sider {
      position: absolute;
      left: 0;
      top: 0;
      transform: translateX(-100%);
      transition: all ease 0.5s;
      z-index: 999;
      height: 100%;
    }

    .sider.expanded {
      transform: unset;
    }
  }
}
