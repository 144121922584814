$z-index: 9;
.viewer {
  height: 100%;
  width: 100%;

  .model-viewer {
    height: 100%;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .created-by {
    position: fixed;
    top: 0.6rem;
    right: 0;
    width: 9.88rem;
    color: rgb(116, 116, 116);
    pointer-events: none;
    opacity: 0.7;
    z-index: $z-index;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .fab {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
  }

  .fab:hover {
    background-color: #f7f7f7;
  }

  .actions {
    display: flex;
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: $z-index;
    gap: 10px;
  }

  .splash-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: $z-index + 1;
    background-color: white;
    opacity: 1;
    .splash-screen-video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .spin-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 9px;
      position: absolute;
      bottom: 17px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  model-viewer::part(default-ar-button) {
    display: none;
  }
}
